import { CTAButton } from "@comelz/ccloud-react-components";
import { RemeshingProjectDigest } from "cadius-backend";
import { ItemDataset } from "cadius-components";
import React, { useEffect } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { ProjectList } from "../components/ProjectList";
import { AppRoutes } from "../routes";

import "./RemeshingProjectsRoute.scss";

export interface Props extends RouteComponentProps {
  areShownAsGrid?: boolean;

  onActionClick: (dataset: ItemDataset) => void;

  onToggleView: () => void;

  projectDigests: RemeshingProjectDigest[];

  startPollingRemeshingProjectDigests: (ms: number) => void;

  stopPollingRemeshingProjectDigests: () => void;
}

declare const CADIUS_FORMS_URL: string;

/**
 * Component rendered when the app is at /remeshing_projects, either because the
 * user navigated to, or because we programmatically redirected to.
 */
export const RemeshingProjectsRoute: React.FC<Props> = ({
  areShownAsGrid,
  onActionClick,
  onToggleView,
  projectDigests,
  startPollingRemeshingProjectDigests,
  stopPollingRemeshingProjectDigests,
}) => {
  useEffect(() => {
    startPollingRemeshingProjectDigests(10000);
    return () => {
      stopPollingRemeshingProjectDigests();
    };
  }, []);

  const onDelete = (projectId: string) => {
    onActionClick({
      actionId: "delete",
      projectId,
    });
  };

  const onClick = (projectId: string) => {
    const url = `${CADIUS_FORMS_URL}/#/${projectId}`;
    window.open(url, "_blank");
  };

  const projectActions = (prj: RemeshingProjectDigest) => {
    const handler = (evt: any) => {
      evt.preventDefault();
      const msg = `Are you sure to delete "${prj.name}"`;
      if (confirm(msg)) {
        onDelete(prj.id);
      }
    };
    return (
      <div className="actions">
        {prj.cal_url && <><a href={prj.cal_url}>CAL</a> - </>}
        <a href="#" onClick={handler}>remove</a>
      </div>);
  };

  return (
    <div className="">
      <div className="cmp-panel-header">
        <h1>Your lasts</h1>
        <Link to={AppRoutes.UploadLast}>
          <CTAButton text="Upload new Last" />
        </Link>
      </div>
      <div>
        {areShownAsGrid ? (
          "grid"
          // <ProjectGrid
          //     onProjectClick={onProjectClick}
          //     onActionClick={onActionClick}
          //     projects={projectDigests.map(toProject)}
          //     style={{ backgroundColor: "darkgray" }}
          // />
        ) : (
          <ProjectList
            projects={projectDigests}
            onClick={onClick}
            actions={projectActions} />
        )}
      </div>
    </div>);
};
