import { Dispatch, Middleware } from "redux";

import { Action } from "../actions/network";
import { setLoader } from "../actions/ui";
import { AppState, CadiusAction } from "../reducers/interfaces";

export const middleware: Middleware<{}, AppState, Dispatch<CadiusAction<any>>> = (
  { dispatch, getState }) => {
  return function nextResolver(next: Dispatch<CadiusAction>) {
    return async function actionDispatcher(action: CadiusAction) {

      switch (action.type) {
        case Action.BEGIN_NETWORK_REQUEST: {
          const progress = 50; // just for testing...
          return next(setLoader(progress));
        }

        case Action.END_NETWORK_REQUEST: {
          return next(setLoader(undefined));
        }

        default: {
          return next(action);
        }
      }
    };
  };
};
