import React, { useContext } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";

import { Menu } from "../components/Menu";
import { AuthContext } from "../contexts";
import { StyleProjectConnected } from "../redux";
import { AppRoutes } from "../routes";
import { logout } from "../utils";
import { DashboardPage } from "./DashboardPage";

interface Props extends RouteComponentProps<{ id: string }> { }

export const StyleProjectRoute: React.FC<Props> = ({ match }) => {
  const id = match.params.id;
  const { user } = useContext(AuthContext);

  return user ? (
    <DashboardPage>
      <React.Fragment>
        <Menu onClickLogout={logout} user={user} />
        <StyleProjectConnected requestedProjectId={id} />
      </React.Fragment>
    </DashboardPage>
  ) : (
    <Redirect
      from={`${AppRoutes.StyleProjects}/${id}`}
      to={AppRoutes.DefaultForUnauthenticated}
    />
  );
};
