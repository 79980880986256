import { Footer } from "cadius-components";
import React from "react";

import { Header } from "../components";

import "./dashboard-page.scss";

interface Props extends React.ComponentProps<"div"> { }

export const DashboardPage: React.FC<Props> = ({ children }) => {
  return (
    <div className="dashboard-page">
      <Header style={{ gridArea: "header" }} />
      <div style={{ gridArea: "content" }}>
        <div className="content-with-menu-and-main">{children}</div>
      </div>
      <Footer style={{ gridArea: "footer" }} />
    </div>
  );
};
