import { ICON, LoginModalForm, Logo, svgIcon } from "cadius-components";
import clsx from "clsx";
import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { AuthContext } from "../../contexts";
import { AppRoutes } from "../../routes";
import { login, logout, resetPassword } from "../../utils";

import "./header.scss";

export interface Props extends React.ComponentProps<"header"> { }

/**
 * TODO: make this component more generic, so it can be moved to
 * cadius-components. For example, react-router-dom's Link should not stay in
 * the generic Header component.
 */
export const Header: React.FC<Props> = ({ className, style }) => {
  const { user } = useContext(AuthContext);

  const onAvatarClick = () => {
    alert("TODO: perform some action on avatar click (maybe show user menu?)");
  };

  return (
    <header
      className={clsx(className, "box", "header-container")}
      style={style}
    >
      {/* TODO: how to design the logo? */}
      <div>
        <Link to={AppRoutes.Home} style={{ cursor: "pointer" }}>
          <Logo />
        </Link>
        <span>|</span>
        <span>Dashboard</span>
      </div>
      <div
        className="margin-left-for-all-children-except-first"
        style={{ alignItems: "center", display: "flex" }}
      >
        {user ? (
          <div className="margin-left-for-all-children-except-first">
            <button
              className={clsx(
                "tool-button",
                "margin-left-for-all-children-except-first"
              )}
              onClick={logout}
              style={{ display: "inline-flex" }}
              type="button"
            >
              <span>Logout</span>
              {svgIcon(ICON.EXIT)}
            </button>
            <span>{user.name}</span>
          </div>
        ) : (
          <LoginModalForm
            onIForgotMyPassword={resetPassword}
            onSubmitLoginFormData={login}
          />
        )}
        <button
          className={clsx("tool-button")}
          onClick={onAvatarClick}
          style={{ display: "inline-flex" }}
          type="button"
        >
          {/* TODO: replace with ICON.USER when it is merged */}
          {svgIcon(ICON.HAMBURGER)}
        </button>
      </div>
    </header>
  );
};
