import {
  client,
  initClient,
  RemeshingProject as PicassoRemeshingProject,
  StyleProject as PicassoStyleProject,
} from "cadius-backend";
import { Dispatch, Middleware } from "redux";

import { setNetworkError } from "../actions/error";
import {
  beginNetworkRequest,
  endNetworkRequest,
} from "../actions/network";
import {
  Action,
  FetchProject,
  ProjectCommandAction,
  RequestOperationOnProject,
  setRemeshingProject,
  setRemeshingProjectDigests,
  setStyleProject,
  setStyleProjectDigests,
  StartPollingProjectDigests,
  UploadCal,
  UploadLast,
} from "../actions/project";
import { setUploadInProgress } from "../actions/ui";
import { AppState } from "../reducers/interfaces";

// goTo change the app location, it can be used to only navigate **inside the same app**.
// Example:
// - goTo("/style_projects")
// - goTo(AppRoutes.StyleProjects)
function goTo(target: string) {
  // it's safe to use location.hash because we know that <App /> uses an HashRouter
  window.location.hash = `#${target}`;
}

declare const CADIUS_CAD3DR_URL: string;
declare const CADIUS_FORMS_URL: string;

/**
 * Each one of these numbers represents the ID of the timer that is set in the
 * application. For example, we poll the remeshing project digests and the style
 * project digests. By keeping track of these IDs we can clear the timers when
 * we no longer need to poll the data.
 */
interface Timers {
  remeshingProjectDigestsPolling?: number;
  styleProjectDigestsPolling?: number;
}

/**
 * Middleware for project-related actions.
 *
 * This middleware handles project-related "command" actions and can dispatch
 * project-related "document" actions (handled by a reducer), actions to notify
 * of the beginning/end of network requests, ui action, errors.
 * @see docs in middlewares/index.ts
 */
export const middleware: Middleware<{}, AppState, Dispatch> = (
  { dispatch, getState }) => {
  const timers: Timers = {
    remeshingProjectDigestsPolling: undefined,
    styleProjectDigestsPolling: undefined,
  };

  return function nextResolver(next) {
    return async function actionDispatcher(action: ProjectCommandAction) {

      await initClient("u01");
      const pc = client();

      const maybeSetRemeshingProjectDigests = async () => {
        next(beginNetworkRequest());
        try {
          const projectDigests = await pc.remeshes.fetchList();
          projectDigests.sort((a, b) => a.name.localeCompare(b.name));
          next(setRemeshingProjectDigests(projectDigests));
        } catch (err) {
          const message = `Failed to fetch remeshing project digests.`;
          next(setNetworkError(err, message));
        }
        next(endNetworkRequest());
      };

      const maybeSetStyleProjectDigests = async () => {
        next(beginNetworkRequest());
        try {
          const projectDigests = await pc.styles.fetchList();
          projectDigests.sort((a, b) => a.name.localeCompare(b.name));
          next(setStyleProjectDigests(projectDigests));
        } catch (err) {
          const message = `Failed to fetch style project digests.`;
          next(setNetworkError(err, message));
        }
        next(endNetworkRequest());
      };

      // This function is defined here because it's a thunk, namely it
      // dispatches multiple actions.
      const pollRemeshingProjectDigests = async () => {
        await maybeSetRemeshingProjectDigests();
      };

      // This function is defined here because it's a thunk, namely it
      // dispatches multiple actions.
      const pollStyleProjectDigests = async () => {
        await maybeSetStyleProjectDigests();
      };

      switch (action.type) {
        case Action.START_POLLING_REMESHING_PROJECT_DIGESTS: {
          const { payload } = action as StartPollingProjectDigests;
          await maybeSetRemeshingProjectDigests();

          timers.remeshingProjectDigestsPolling =
            window.setInterval(pollRemeshingProjectDigests, payload.ms);

          break;
        }

        case Action.STOP_POLLING_REMESHING_PROJECT_DIGESTS: {
          window.clearInterval(timers.remeshingProjectDigestsPolling);
          timers.remeshingProjectDigestsPolling = undefined;
          break;
        }

        case Action.START_POLLING_STYLE_PROJECT_DIGESTS: {
          const { payload } = action as StartPollingProjectDigests;
          await maybeSetStyleProjectDigests();

          timers.styleProjectDigestsPolling =
            window.setInterval(pollStyleProjectDigests, payload.ms);

          break;
        }

        case Action.STOP_POLLING_STYLE_PROJECT_DIGESTS: {
          window.clearInterval(timers.styleProjectDigestsPolling);
          timers.styleProjectDigestsPolling = undefined;
          break;
        }

        case Action.REQUEST_OPERATION_ON_REMESHING_PROJECT: {
          const { payload } = action as RequestOperationOnProject;
          const { actionId, projectId } = payload;

          // TODO: define enum for all available operations on a project?
          if (actionId === "delete") {
            next(beginNetworkRequest());
            try {
              await pc.remeshes.delete(projectId);
            } catch (err) {
              const message = `Failed to delete remeshing project id ${projectId}`;
              next(setNetworkError(err, message));
            }
            return next(endNetworkRequest());
          } else {
            alert(JSON.stringify(action, null, 2));
            break;
          }
        }

        case Action.REQUEST_OPERATION_ON_STYLE_PROJECT: {
          const { payload } = action as RequestOperationOnProject;
          const { actionId, projectId } = payload;

          // TODO: define enum for all available operations on a project?
          if (actionId === "delete") {
            next(beginNetworkRequest());
            try {
              await pc.styles.delete(projectId);
            } catch (err) {
              const message = `Failed to delete style project id ${projectId}`;
              next(setNetworkError(err, message));
            }
            return next(endNetworkRequest());
          } else {
            alert(JSON.stringify(action, null, 2));
            break;
          }
        }

        case Action.FETCH_REMESHING_PROJECT: {
          const { payload } = action as FetchProject;
          next(beginNetworkRequest());
          const projectId = payload.projectId;
          try {
            const project = await pc.remeshes.fetch(projectId);
            next(setRemeshingProject(project));
          } catch (err) {
            const message = `Failed to fetch remeshing project id ${projectId}`;
            next(setNetworkError(err, message));
          }
          return next(endNetworkRequest());
        }

        case Action.FETCH_STYLE_PROJECT: {
          const { payload } = action as FetchProject;
          next(beginNetworkRequest());
          const projectId = payload.projectId;
          try {
            const project = await pc.styles.fetch(projectId);
            next(setStyleProject(project));
          } catch (err) {
            const message = `Failed to fetch style project id ${projectId}.`;
            next(setNetworkError(err, message));
          }
          return next(endNetworkRequest());
        }

        case Action.UPLOAD_CAL: {
          const { payload } = action as UploadCal;
          const { calFile, projectName } = payload;

          let cal: ArrayBuffer | undefined;
          let extractedSuccesfully = false;
          let message: string;
          let styleProject: PicassoStyleProject | undefined;

          next(setUploadInProgress("style"));
          // 1 create a new empty style project
          next(beginNetworkRequest());
          try {
            styleProject = await pc.styles.create({ name: projectName });
          } catch (err) {
            message = `Failed to create style project ${projectName}`;
            next(setNetworkError(err, message));
          }
          next(endNetworkRequest());

          // 2 create array buffer from .cal file
          if (styleProject) {
            next(beginNetworkRequest());
            try {
              cal = await new Response(calFile).arrayBuffer();
            } catch (err) {
              message = `Failed to create array buffer for .cal file`;
              next(setNetworkError(err, message));
            }
            next(endNetworkRequest());
          }

          // 3 import a `.cal` file in the newly created project
          if (cal && styleProject) {
            next(beginNetworkRequest());
            try {
              styleProject = await pc.styles.extractCal(styleProject.id, cal);
              extractedSuccesfully = true;
            } catch (err) {
              message = `Failed extractCal for styleProject ${styleProject.id}`;
              next(setNetworkError(err, message));
            }
            next(endNetworkRequest());
          }

          if (cal && styleProject && extractedSuccesfully) {
            console.log("CAL UPLOAD AND NEW STYLE PROJECT EXTRACTED.",
              "cal", cal, "styleProject", styleProject,
              "extractedSuccesfully?", extractedSuccesfully);
          } else {
            console.log("CAL UPLOAD FAILED. ROLLBACK?",
              "cal", cal, "styleProject", styleProject,
              "extractedSuccesfully?", extractedSuccesfully);
          }

          if (styleProject && !extractedSuccesfully) {
            // something goews wrong
            next(beginNetworkRequest());
            try {
              await pc.styles.delete(styleProject.id);
            } catch (err) {
              // TODO: if we failed to delete the newly created style project we
              // should retry until we succeed.
              message = `Failed delete styleProject ${styleProject.id}`;
              next(setNetworkError(err, message));
            }
            next(endNetworkRequest());
          }

          next(setUploadInProgress(undefined));
          if (styleProject) {
            // open the new style project in cadius-cad3dr
            window.open(`${CADIUS_CAD3DR_URL}/#/projects/${styleProject.id}`, "_blank");
          }
          goTo("/style_projects");
          break;
        }

        case Action.UPLOAD_LAST: {
          const { payload } = action as UploadLast;
          const { lastName, lastImage, notes, stlFile } = payload;

          let stl: ArrayBuffer | undefined;
          let message: string;
          let remeshingProject: PicassoRemeshingProject | undefined;
          let originalModelUrl: string | undefined;

          next(setUploadInProgress("remeshing"));
          // 1 create a new empty remeshing project
          next(beginNetworkRequest());
          try {
            remeshingProject = await pc.remeshes.create({ name: lastName });
          } catch (err) {
            message = `Failed to create remeshing project ${lastName}`;
            next(setNetworkError(err, message));
          }
          next(endNetworkRequest());

          // 2 create array buffer from .stl file
          if (remeshingProject) {
            next(beginNetworkRequest());
            try {
              stl = await new Response(stlFile).arrayBuffer();
            } catch (err) {
              message = `Failed to create array buffer for .stl file`;
              next(setNetworkError(err, message));
            }
            next(endNetworkRequest());
          }

          // 3 import a `.stl` file in the remeshing project
          // once the .stl has been uploaded, the remeshing project can finally
          // be shown in cadius-forms.
          if (stl && remeshingProject) {
            next(beginNetworkRequest());
            try {
              const downloadUrl = await pc.remeshes.changeOriginalModel(remeshingProject.id, stl);
              originalModelUrl = downloadUrl.url;
            } catch (err) {
              message = `Failed to set STL model for remeshingProject ${remeshingProject.id}`;
              next(setNetworkError(err, message));
            }
            next(endNetworkRequest());
          }

          if (stl && remeshingProject && originalModelUrl) {
            next(setRemeshingProject(remeshingProject));
          }

          if (originalModelUrl) {
            console.log("STL UPLOAD AND NEW REMESHING PROJECT CREATED.");
          } else {
            console.log("Remeshing project creation incomplete. ROLLBACK?",
              "stl", stl, "remeshingProject", remeshingProject,
              "originalModelUrl?", originalModelUrl);
          }
          console.log("=== UPLOAD LAST IN MIDDLEWARE (notes and lastImage are not used) ===", notes, lastImage);

          next(setUploadInProgress(undefined));
          if (remeshingProject) {
            // open the new remeshing project in cadius-forms
            window.open(`${CADIUS_FORMS_URL}/#/${remeshingProject.id}`, "_blank");
          }
          goTo("/remeshing_projects");
          break;
        }

        default: {
          return next(action);
        }
      }
    };
  };
};
